nav {
    background: rgba(0, 94, 126, 0.3);
    width: max-content;
    display: flex;
    padding: 15px 50px;
    z-index: 20;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 60px;
    gap: 50px;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: var(--color-light);
    backdrop-filter: blur(15px);
}

nav button {
    color: rgba(255, 255, 255, 0.4);
    display: flex;
    align-items: center;
    font-size: 25px;
    background: transparent;
    transition: var(--transition);
}

nav button:hover {
    color: var(--color-white);
}

nav button.active {
    color: var(--color-white);
}

nav button.dis {
    color: rgba(255, 255, 255, 0.4);
}

.container__smooth-sclroll {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    overflow: hidden;
}