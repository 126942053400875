.container__portfolio {
    overflow: hidden;
}

.work__items {
    position: relative;
    margin: 50px 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    left: 300px;
    opacity: 0;
    transition: var(--transition-smooth);
}

.work__items.active {
    opacity: 1;
    left: 0px;
}

.worck__item{
    background: var(--color-bg-variant);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.work__image_container {
    margin-top: 15px;
    width: 100%;
    position: absolute;
    display: grid;
    place-items: center;
}

.work__image {
    width: 70%;
    border: 2px solid #3d8ea9;
    border-radius: 10px;
    overflow: hidden;
    transition: var(--transition);
}

.work__image:hover {
    width: 90%;
}

.work__description {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc(100% - 100vw / 9);
    margin-bottom: 15px;
}

.work__sub_description {
    text-align: start;
    font-weight: 200;
    line-height: 18px;
    padding: 5px 15% 0px;
}

@media screen and (max-width: 1024px ){
    .work__items {
        margin-bottom: 0px;
        grid-template-columns: repeat(2, 1fr);
    }
    .work__description {
        margin-top: calc(100% - 100vw / 4.5);
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 600px ){
    .work__items {
        margin: 50px 10px 0px;
        grid-template-columns: 1fr;
    }

    .work__description {
        margin-top: calc(100% - 100vw / 2);
        margin-bottom: 15px;
    }
}