@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: rgb(0, 65, 99);
  --color-bg-variant: rgb(0, 94, 126);
  --color-primary: rgb(156, 174, 192);
  --color-primary-variant: rgb(101, 124, 148);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.7);

  --transition: all 400ms ease;
  --transition-smooth: all 1.5s ease-out;

  --container-width-lg: 65%;
  --container-width-md: 85%;
  --container-width-ms: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar{
  display: none;
}

body {
  position: relative;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
}

/*====================== General Styles ============================*/

.container {
  width: var(--container-width-lg);
  left: 0px;
  right: 0px;
  margin: auto;
  padding-top: 50px;
  text-align: center;
  height: 100%;
}

h2 {
  font-size: 25px;
  font-weight: 300;
  white-space: nowrap;
  letter-spacing: 5px;
  text-transform: uppercase;
  padding: 0px;
  color: var(--color-primary);
}

h3 {
  font-size: 20px;
  margin: 0px;
  padding: 0px;
}

h4 {
  font-size: 15px;
  font-weight: 100;
  margin: 0px;
  padding: 0px;
}

p {
  text-align: left;
  margin-top: 12px;
  font-size: 15px;
  font-weight: 100;
  line-height: 18px;
}

small {
  font-size: 12px;
  font-weight: 100;
}

section {
  text-align: center;
  z-index: 1;
  background: var(--color-bg);
  display: block;
  position: relative;
  width: 100%;
  height: 100vh;
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  height: 30px;
  display: inline-block;
  color: var(--color-primary-variant);
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  line-height: 18px;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/*======================= Media Queries (Medium) =============================*/

@media screen and (max-width: 1024px) {
  .container{
    width: var(--container-width-md);
  }

  section {
    height: max-content;
  }
}

/*======================= Media Queries (Small) =============================*/

@media screen and (max-width: 600px) {
  .container{
    width: var(--container-width-sd);
  }

  h2 {
    font-size: 15px;
  }

  section {
    height: max-content;
  }
}