.about__container {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 35% 60%;
    gap: 5%;
    overflow: hidden;
}

.about__me {
    position: relative;
    width: 100%;
    overflow: hidden;
    aspect-ratio: 1/1;
    border-radius: 10px;
    display: grid;
    place-items: center;
    left: -200px;
    opacity: 0;
    transition: var(--transition-smooth);
}

.about__me.active {
    opacity: 1;
    left: 0px;
}

.about__content {
    position: relative;
    height: max-content;
    left: 200px;
    opacity: 0;
    transition: var(--transition-smooth);
}

.about__content.active {
    opacity: 1;
    left: 0px;
}

.about__cards {
    height: max-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5%;
}

.about__card {
    display: flex;
    align-items: center;
    justify-content: left;
    background: transparent;
    border-radius: 10px;
}

.about__title {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-bg-variant);
    border-right: 1px solid var(--color-primary);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: var(--transition);
}

.about__title:hover {
    background: transparent;
    border-color: var(--color-primary);
}

.about__content h5 {
    margin-top: 12px;
    font-size: 18px;
    font-weight: 300;
    line-height: 18px;
    text-align: start;
    color: var(--color-light);
}

.about__card small {
    margin-left: 10px;
    font-size: 12px;
}

.about__content p {
    color: var(--color-light);
}

@media screen and (max-width: 600px ){
    .about__container {
        margin: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 15px;
    }

    .about__me {
        width: 100%;
    }

    .about__cards {
        grid-template-columns: 1fr;
    }

    .about__card {
        width: 100%;
    }

    .about__content p {
        color: var(--color-light);
        font-size: 18px;
        line-height: 15px;
    }

    .about__content h5 {
        margin-top: 25px;
    }
}