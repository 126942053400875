header {
    margin: 0px;
    background-image: radial-gradient(
        farthest-corner at 100% 40px,
        rgb(255, 255, 255) 10%,
        rgb(0, 175, 202) 50%
    );
    position: relative;
    width: 100%;
    height: 100vh;
}

.header__container {
    text-align: center;
    height: 100%;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    padding-top: 150px;
}

.header__title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

h1 {
    position: relative;
    font-size: 50px;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 12px;
    padding: 0px;
    color: var(--color-primary-variant);

    animation: title-right 1.5s ease-out;
}

@keyframes title-right {
    0% {
        right: 100px;
        opacity: 0;
    }
    100% {
        right: 0px;
        opacity: 1;
    }
}

.header__span {
    position: relative;
    padding-right: 20px;
    font-size: 50px;
    font-weight: 800;
    white-space: nowrap;
    letter-spacing: 12px;
    text-transform: uppercase;
    color: rgb(211, 231, 246);

    animation: title-left 1.5s ease-out;
}

@keyframes title-left {
    0% {
        left: 300px;
        opacity: 0;
    }
    100% {
        left: 0px;
        opacity: 1;
    }
}

.header__subtitle {
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 5px;
    margin-top: 100px;
    color: var(--color-primary-variant);
    opacity: 0;

    animation: subtitle-top 1s ease-out 1s;
    animation-fill-mode: forwards;
}

@keyframes subtitle-top {
    0% {
        top: 30px;
        opacity: 0;
    }
    100% {
        top: 0px;
        opacity: 1;
    }
}

.header__socials {
    width: 100%;
    position: relative;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    bottom: 20px;
}

.header__socials::after {
    content: '';
    width: 15px;
    height: 1px;
    background: var(--color-primary-variant);
}

.header__socials::before {
    content: '';
    width: 15px;
    height: 1px;
    background: var(--color-primary-variant);
}

.header__socials a {
    color: var(--color-primary-variant);
    font-size: 20px;
}

.header__socials a:hover {
    color: rgb(211, 231, 246);
}

@media screen and (max-width: 1024px ){
    h1 {
        font-size: 40px;
    }
    
    .header__span {
        font-size: 40px;
    }
}

@media screen and (max-width: 600px ){
    h1 {
        font-size: 20px;
        font-weight: 300;
        margin: 5px;
    }
    
    .header__span {
        font-size: 20px;
        font-weight: 600;
    }

    .header__h3 {
        font-size: 15px;
        font-weight: 400;
        margin-top: 0px;
    }
}