.contacts__container {
    width: 100%;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 35% 60%;
    grid-gap: 5%;
    overflow: hidden;
}

.contacts__options {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    left: -300px;
    opacity: 0;
    transition: var(--transition-smooth);
}

.contacts__options.active {
    left: 0px;
    opacity: 1;
}

.contact__option {
    display: flex;
    flex-direction: column;
    background: var(--color-bg-variant);
    padding: 15px;
    border-radius: 10px;
    text-align: center;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 19px;
}

.form__options {
    position: relative;
    left: 300px;
    opacity: 0;
    transition: var(--transition-smooth);
}

.form__options.active {
    left: 0px;
    opacity: 1;
}

input, textarea {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    background: transparent;
    border: 2px solid var(--color-primary);
    resize: none;
    color: var(--color-white);
}

@media screen and (max-width: 1024px ) {
    .contacts__container {
        padding-bottom: 100px;
    }
}

@media screen and (max-width: 600px ) {
    .contacts__container {
        grid-template-columns: 1fr;
        grid-gap: 2%;
        padding-bottom: 100px;
    }

    .contact__option {
        margin: 0px 10px;
    }

    form {
        margin: 0px 10px;
    }
}